exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-client-intake-form-js": () => import("./../../../src/pages/client-intake-form.js" /* webpackChunkName: "component---src-pages-client-intake-form-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-homeowners-guide-to-residential-construction-defects-free-download-js": () => import("./../../../src/pages/homeowners-guide-to-residential-construction-defects-free-download.js" /* webpackChunkName: "component---src-pages-homeowners-guide-to-residential-construction-defects-free-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practice-areas-js": () => import("./../../../src/pages/practice-areas.js" /* webpackChunkName: "component---src-pages-practice-areas-js" */),
  "component---src-pages-schedule-consultation-js": () => import("./../../../src/pages/schedule-consultation.js" /* webpackChunkName: "component---src-pages-schedule-consultation-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-types-of-claims-js": () => import("./../../../src/pages/types-of-claims.js" /* webpackChunkName: "component---src-pages-types-of-claims-js" */),
  "component---src-pages-types-of-projects-js": () => import("./../../../src/pages/types-of-projects.js" /* webpackChunkName: "component---src-pages-types-of-projects-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-attorney-bio-js": () => import("./../../../src/templates/attorney-bio.js" /* webpackChunkName: "component---src-templates-attorney-bio-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-practice-area-js": () => import("./../../../src/templates/practice-area.js" /* webpackChunkName: "component---src-templates-practice-area-js" */),
  "component---src-templates-types-of-claims-js": () => import("./../../../src/templates/types-of-claims.js" /* webpackChunkName: "component---src-templates-types-of-claims-js" */),
  "component---src-templates-types-of-projects-js": () => import("./../../../src/templates/types-of-projects.js" /* webpackChunkName: "component---src-templates-types-of-projects-js" */)
}

